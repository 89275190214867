// eslint-disable-next-line
import { Pod, PodLoadState, Usergroup } from '../shared/src/types/Pod'
import { PdfFile, Folder, PdfPage, Tag, Link } from '../shared/src/types/Content'
import { iAnnotation, iComment, iLink, iWeblink, iTag, iEmotion, Interaction, iReadingQuestion, interactionAnchor } from '../shared/src/types/Interaction'
import { Thread, Message } from '../shared/src/types/Message'
import { UserInfo } from "../shared/src/types/User"
import { makeObservable, observable, action } from "mobx"
import uiStore from '../stores/uiStore'
import sessionStore from '../stores/sessionStore'

import murmurhash from 'murmurhash'
import { Op, noOp } from '../shared/src/types/Ops'
import { OpCode } from '../shared/src/types/OpCodes'
import { InteractionLocation, InteractionIdLookupTable, ReactionBlock, downloadableType, PageLookupTable } from '../shared/src/types/Miscs'
import { convertInteractionTypeSingularToPlural } from '../helper/Helper'

const mergeToAdd = (object:Interaction|Message, data:Interaction|Message) => {
  Object.keys(data).forEach((prop:string) => {
    //@ts-ignore
    if (JSON.stringify(object[prop]) !== JSON.stringify(data[prop])) object[prop] = data[prop]
  })
}

export interface PodI extends Pod {
  addPdfFile: (pdfFile: PdfFile) => void
  setStatus: (status: PodLoadState) => void
  setLastSyncOid: (oid: number) => void
  setLoadStatus: (status: number) => void
  applyOp: (op: Op) => void

  addPdfPage: (op: any) => void
  addFolder: (op: any) => void
  addTag: (data: Tag) => void
  addAnnotation: (data: iAnnotation) => void
  addComment: (data: iComment) => void
  addEmotion: (data: iEmotion) => void
  addLink: (data: iLink) => void
  addReadingQuestion: (data:iReadingQuestion) => void
  addTagging: (data: iTag) => void
  addWeblink: (data: iWeblink) => void
  deleteLink: (linkId: string) => void
  deleteInteraction: (interactionId:string) => void
  addThread: (op: any) => void
  addMessage: (op: any) => void

  getNodeFromInteractionId: (interactionId:string) => PdfFile | null
  getPdfFiles: () => PdfFile[]
  getFolders: () => Folder[]
  getAnnotation: (interactionId: string) => iAnnotation | null
  getAnnotations: (nodeId: string) => iAnnotation[] | null
  getComments: (nodeId: string) => iComment[] | null
  getLinks: (nodeId: string) => iLink[] | null
  getReadingQuestions: (nodeId: string) => iReadingQuestion[] | null
  getLinkOther: (link: iLink) => iLink | false
  getWeblinks: (nodeId: string) => iWeblink[] | null
  getTaggings: (nodeId: string) => iTag[] | null
  getEmotions: (nodeId: string) => iEmotion[] | null
  checkForRole: (role: 'Admin'|'Pod'|'Private') => boolean
  getUsergroupByRole: (role: 'Admin'|'Pod'|'Private') => Usergroup
  getInteractionFromThreadId: (threadId: string) => iAnnotation | iComment | iEmotion | iLink | iReadingQuestion | iTag | iWeblink | null

  findInteraction: (interactionId: string) => InteractionLocation | null
  getInteraction: (interactionId: string) => iAnnotation | iComment | iEmotion | iLink | iReadingQuestion | iTag | iWeblink | null
  getInteractionsFromPage: (nodeId: string, pageNumber: number) => Array< iAnnotation | iComment | iEmotion | iLink | iReadingQuestion | iTag | iWeblink > | []
  getInteractionsByCoordinates: (nodeId: string, x: number, y: number, pageNumber: number, currentScale: number, pageElement: DOMRect) => {interaction: Interaction, menuAnchor: any}[] | null
  getMessage: (messageId: string, threadId:string|null) => Message | null
  getThreadFromMessage: (messageId: string) => Thread | null

  fingerprint: (hashed:boolean) => string
  isAllowed: (op: OpCode, objectId: string|number|null) => boolean
  isVisible: (type:'pdfFile'|'thread'|'message'|'annotation'|'comment'|'link'|'tagging'|'weblink'|'emotion'|'interaction', id: string) => boolean
  nodeIsHidden: (nodeId: string) => boolean

  addInteractionToLookupTable: (interaction: Interaction) => void
  deleteInteractionFromLookupTable: (interactionId: string) => void
  addPageToLookupTable: (interaction: Interaction) => void
  deletePageFromLookupTable: (interaction: Interaction) => void
}

const emptyPod:Pod = {
  interactionsLookupTable: {},
  pageLookupTable: {},
  podId: '',
  name:'',
  description:'',
  podColor:null,
  podImageFileId:null,
  podIdHr:'',
  usergroups:{},
  permissions: {},
  userInfos: {},
  allowDownload: [],
  creator: undefined,
  content:{
    folders: {},
    pdfFiles:{},
    tags:{},
    links:{},
    threads: {},
  },
  oerSharedTo:[],
  status:'broken',
  initMaxCoid:0,
  loadtimeMaxOid:0,
  lastSyncOid:0,
  tCreated:0,
  tModified:0,
  outOfSync:false,
  hasKeyphrase:undefined,
}

export class PodClass implements PodI {
  public interactionsLookupTable: InteractionIdLookupTable = {}
  public pageLookupTable: PageLookupTable = {}
  public podId: string = ''
  public name: string = ''
  public creator?: UserInfo|undefined = undefined
  public description: string = ''
  public podColor: string|null = null
  public podImageFileId: string | null = null
  public podIdHr: string|null = null
  public permissions: {[op: string]: boolean}  = {}
  public usergroups: {[usergroupId: string]: Usergroup} = {}
  public allowDownload:downloadableType[] = []
  public content: {
    pdfFiles: {[nodeId: string]: PdfFile},
    folders: {[folderId: string]: Folder},
    tags: {[tagId: string]: Tag},
    links: {[linkId: string]: Link}
    threads: {[threadId: string]: Thread},
  } = {
    pdfFiles: {},
    folders: {},
    tags: {},
    links: {},
    threads: {},
  }
  public oerSharedTo: number[] = []
  public userInfos: {[userId: number]: UserInfo} = {}
  public status: PodLoadState | null = null
  public loadStatus?: number  = 0
  public initMaxCoid: number = 0
  public loadtimeMaxOid: number = 0
  public lastSyncOid: number = 0
  public tCreated: number = 0
  public tModified: number = 0

  public outOfSync?: boolean|undefined = false
  public backendFingerprint?: string
  public serviceWorkerFingerprint?: string
  public hasKeyphrase?: boolean

  constructor(pod: Pod|null, addMobx: boolean = false) {
    if (pod !== null) {
      Object.assign(this, pod)
    }
    else {
      Object.assign(this, emptyPod)
    }
    if (addMobx) makeObservable(this, {
      pageLookupTable: observable,
      name: observable,
      creator: observable,
      description: observable,
      podImageFileId: observable,
      oerSharedTo: observable,
      podColor: observable,
      loadStatus: observable,
      status: observable,
      content: observable,
      permissions: observable,
      usergroups: observable,
      userInfos: observable,
      allowDownload: observable,
      outOfSync: observable,

      getPdfFiles: observable,
      getFolders: observable,
      getAnnotations: observable,
      getComments: observable,
      getLinks: observable,
      getLinkOther: observable,
      getWeblinks: observable,
      getTaggings: observable,
      getEmotions: observable,

      // addPdfFile: action, // we technically don't need to decorate this as an action as long as it is called only from inside doOp (in which case it should be marked 'private')
      applyOp: action,
      setLastSyncOid: action,
      setStatus: action,
      setLoadStatus: action,
    })
  }

  isVisible(type:'pdfFile'|'thread'|'message'|'annotation'|'comment'|'link'|'readingQuestion'|'tagging'|'weblink'|'emotion'|'interaction', id:string) {

    const usergroupsForThisUser = this.usergroups

    switch(type) {
      case 'pdfFile':
        const file = this.content.pdfFiles[id]
        if (file) {
            if (file.userId===sessionStore.session.user.userId) return true
            if (typeof usergroupsForThisUser[file.usergroupId] !== 'undefined') return true
        }
        break;

      case 'thread':
        const thread = this.content.threads[id]
        if (thread) {
          //const interaction = this.
          if (thread.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[thread.usergroupId] !== 'undefined') return true
        }
      break

      case 'message':
        const msgThread = this.getThreadFromMessage(id)
        if (msgThread) {
          if (msgThread.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[msgThread.usergroupId] !== 'undefined') return true
        }
        break;

      case 'annotation':
      case 'comment':
      case 'link':
      case 'readingQuestion':
      case 'tagging':
      case 'weblink':
      case 'emotion':
      case 'interaction':
        const interaction = this.getInteraction(id)
        if (interaction) {
          if (interaction.userId === sessionStore.session.user.userId) return true
          if (typeof usergroupsForThisUser[interaction.usergroupId] !== 'undefined') return true
        }
        break;

      default:
        return false
    }

    return false
  }

  isAllowed(op: OpCode, objectOrParentId: string|number|null = null) {

    // if no object / parent is defined, return the general permissions the user has in this pod
    if (objectOrParentId === null) {
      return this.permissions[op]
    }

    // if an object / parent is defined, determin if the user owns this object (wich gives her all rights), or if
    // the object is visible to her and she generally has the required permission for this operation in this pod
    switch(op) {

      case 'editPod':
      case 'deletePod': {
        // always allow the pod's creator to do this
        const pod:PodI = sessionStore.session.pods.find((p:PodI) => p.podId === objectOrParentId)
        if (pod && pod.creator?.userId === sessionStore.session.user.userId) return true
        }
        return this.permissions[op] // fallback to allow anybody with the necessary permission to do this

      case 'addAnnotation':
      case 'addComment':
      case 'addLink':
      case 'addReadingQuestion':
      case 'addTagging':
      case 'addWeblink':
      case 'addEmotion':
        {
          const file = this.content.pdfFiles[objectOrParentId]
          if (file) {
            if ((this.isVisible('pdfFile', file.nodeId)) && (this.isAllowed(op))) return true
          }
        }
        return false

      case 'editPdfFile':
      case 'deletePdfFile':
        {
          const file = this.content.pdfFiles[objectOrParentId]
          if (file) {
            if (file.userId === sessionStore.session.user.userId) return true
            if ((this.isVisible('pdfFile', file.nodeId)) && (this.isAllowed(op))) return true
          }
        }
        return false

      case 'editAnnotation':
      case 'editComment':
      case 'editEmotion':
      case 'editLink':
      case 'editReadingQuestion':
      case 'editTagging':
      case 'editWeblink':
      case 'deleteAnnotation':
      case 'deleteComment':
      case 'deleteEmotion':
      case 'deleteLink':
      case 'deleteReadingQuestion':
      case 'deleteTagging':
      case 'deleteWeblink':
        // check group of interaction `objectId` to see if the user can edit/delete it
        const interaction = this.getInteraction(objectOrParentId as string)
        if (interaction) {
          if (interaction.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('interaction', interaction.interactionId)) && (this.isAllowed(op))) return true
        }
        return false

      case 'addMessage':
        // check group of the thread `objectOrParentId` to see if the user can contribute to this thread
        const thread = this.content.threads[objectOrParentId]
        if (thread) {
          if (thread.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('thread', thread.threadId as string)) && (this.isAllowed(op))) return true
        }
        return false

      case 'editMessage':
      case 'deleteMessage':
        const message = this.getMessage(objectOrParentId as string)
        if (message) {
          if (message.userId === sessionStore.session.user.userId) return true
          if ((this.isVisible('message', message.messageId)) && (this.isAllowed(op))) return true
        }
        return false

      case 'editUserInfo':
        if (objectOrParentId === sessionStore.session.user.userId) return true
        return this.permissions[op]

      case 'removeUserFromPod':
        if (objectOrParentId === sessionStore.session.user.userId) return true
        return this.permissions[op]

    }
    return false
  }

  nodeIsHidden(nodeId:string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return true
    if (file.hidden) return true
    if (file.folderId) {
      const folder = this.content.folders[file.folderId]
      if (!folder) return true
      if (folder.hidden) return true
    }
    return false
  }

  addInteractionToLookupTable(interaction: Interaction) {
    const interactionId = interaction.interactionId
    const interactionType = interaction.interactionType
    const nodeId = interaction.anchor.nodeId
    // if the hash with this interactionId does not yet exist, create it
    if(interactionId && interactionType && nodeId && this.interactionsLookupTable[interactionId] === undefined) {
      this.interactionsLookupTable[interactionId] = {interactionId: interactionId, interactionType: interactionType, nodeId: nodeId, nodeType: 'pdfFile'}
    }
  }

  deleteInteractionFromLookupTable(interactionId: string) {
    if(interactionId && this.interactionsLookupTable[interactionId]) {
      delete this.interactionsLookupTable[interactionId]
    }
  }

  addPageToLookupTable(interaction: Interaction) {
    const interactionId = interaction.interactionId
    const nodeId = interaction.anchor.nodeId
    const rects = interaction.anchor.rects
    if(interactionId && nodeId && rects && rects.length) {
      let currentPage = null
      for(const rect of rects) {
        const pageNumber = rect.p
        const hash = getPageLookupTableHash(nodeId, pageNumber)
        if(pageNumber !== currentPage) {
          // if no entry exists: create new hash object
          if(this.pageLookupTable[hash] === undefined) {
            this.pageLookupTable[hash] = {}
          }
          this.pageLookupTable[hash][interactionId] = true
          // prevent to add same interaction multiple times
          currentPage = pageNumber
        }
      }
    }
  }

  deletePageFromLookupTable(interaction: Interaction) {
    const interactionId = interaction.interactionId
    const nodeId = interaction.anchor.nodeId
    const rects = interaction.anchor.rects
    if(interactionId && nodeId && rects && rects.length) {
      let currentPage = null
      for(const rect of rects) {
        const pageNumber = rect.p
        const hash = getPageLookupTableHash(nodeId, pageNumber)
        if(pageNumber !== currentPage) {
          const pageLookupTable = this.pageLookupTable[hash]
          if(pageLookupTable) {
            // delete interaction in page hash object
            delete this.pageLookupTable[hash][interactionId]
            // delete page hash object if there are no interactions left
            if(Object.keys(pageLookupTable).length === 0) {
              delete this.pageLookupTable[hash]
            }
          }
          // prevent to delete same interaction multiple times
          currentPage = pageNumber
        }
      }
    }
  }

  applyOp(op: Op|noOp) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`ApplyOp(#${op.oid}: ${op.op})`)
    if (op.oid) this.setLastSyncOid(op.oid)

    switch(op.op) {

      case 'noop':
        break

      case 'addPdfFile':
        const addPdfFileData: PdfFile = {
          nodeId: op.data.nodeId,
          name: op.data.name,
          description: op.data.description,
          status: op.data.status,
          weight: op.data.weight,
          folderId: op.data.folderId,
          hidden: op.data.hidden,
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          hash: op.data.hash,
          size: op.data.size,
          nofPages: op.data.nofPages,
          pages: [],
          annotations: {},
          comments: {},
          emotions: {},
          links: {},
          readingQuestions: {},
          weblinks: {},
          taggings: {},
          coid: op.data.coid || null,                   // should we take this from op.oid? -> No, better to have the coid as part of the data-object than to rely on an implicit property higher up?
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addPdfFile(addPdfFileData)
        break

      case 'editPdfFile':
        if (this.content.pdfFiles[op.data.nodeId]) {
          if (typeof op.data.mods.name !== 'undefined') this.content.pdfFiles[op.data.nodeId].name = op.data.mods.name
          if (typeof op.data.mods.description !== 'undefined') this.content.pdfFiles[op.data.nodeId].description = op.data.mods.description
          if (typeof op.data.mods.folderId !== 'undefined') this.content.pdfFiles[op.data.nodeId].folderId = op.data.mods.folderId
          if (typeof op.data.mods.weight !== 'undefined') this.content.pdfFiles[op.data.nodeId].weight = op.data.mods.weight
          if (typeof op.data.mods.hidden !== 'undefined') this.content.pdfFiles[op.data.nodeId].hidden = op.data.mods.hidden
          if (typeof op.data.mods.status !== 'undefined') this.content.pdfFiles[op.data.nodeId].status = op.data.mods.status
          if (typeof op.data.mods.hash !== 'undefined') this.content.pdfFiles[op.data.nodeId].hash = op.data.mods.hash
          if (typeof op.data.mods.size !== 'undefined') this.content.pdfFiles[op.data.nodeId].size = op.data.mods.size
          if (typeof op.data.mods.nofPages !== 'undefined') this.content.pdfFiles[op.data.nodeId].nofPages = op.data.mods.nofPages
          if (typeof op.data.mods.tModified !== 'undefined') this.content.pdfFiles[op.data.nodeId].tModified = op.data.mods.tModified
        }
        break;

      case 'deletePdfFile':
        const links = this.getLinks(op.data.nodeId)
        links?.forEach((link) => this.deleteLink(link.linkId))
        const interactionsToDelete = [
          ...(this.getAnnotations(op.data.nodeId)?.map(i => i.interactionId)||[]),
          ...(this.getComments(op.data.nodeId)?.map(i => i.interactionId)||[]),
          ...(this.getEmotions(op.data.nodeId)?.map(i => i.interactionId)||[]),
          ...(links?.map(l => l.interactionId)||[]),
          ...(this.getLinks(op.data.nodeId)?.map(i => i.interactionId)||[]),
          ...(this.getReadingQuestions(op.data.nodeId)?.map(i => i.interactionId)||[]),
          ...(this.getWeblinks(op.data.nodeId)?.map(i => i.interactionId)||[]),
        ]
        Object.keys(this.content.threads).forEach((threadId:string) => {
          if (interactionsToDelete.indexOf(this.content.threads[threadId].interactionId)>-1) {
            delete this.content.threads[threadId]
          }
        })
        delete this.content.pdfFiles[op.data.nodeId]
        break;

      case 'editFolder':
        if (this.content.folders[op.data.folderId]) {
          if (typeof op.data.mods.description !== 'undefined') this.content.folders[op.data.folderId].description = op.data.mods.description
          if (typeof op.data.mods.name !== 'undefined') this.content.folders[op.data.folderId].name = op.data.mods.name
          if (typeof op.data.mods.weight !== 'undefined') this.content.folders[op.data.folderId].weight = op.data.mods.weight
          if (typeof op.data.mods.hidden !== 'undefined') this.content.folders[op.data.folderId].hidden = op.data.mods.hidden
          if (typeof op.data.mods.tModified !== 'undefined') this.content.folders[op.data.folderId].tModified = op.data.mods.tModified
        }
        break

      case 'deleteFolder':
        Object.keys(this.content.pdfFiles).forEach((nodeId) => {
          if (this.content.pdfFiles[nodeId].folderId === op.data.folderId) this.content.pdfFiles[nodeId].folderId = ''
        })
        delete this.content.folders[op.data.folderId]
        break

      case 'addPdfPage':
        const addPdfPageData: PdfPage  = {
          nodeId: op.data.nodeId,
          no: op.data.no,
          width: op.data.width,
          height: op.data.height,
          rotation: op.data.rotation,
          mTop: op.data.mTop,
          mRight: op.data.mRight,
          mBottom: op.data.mBottom,
          mLeft: op.data.mLeft,
          fulltext: op.data.fulltext,
          tSeen: op.data.tSeen,
          dSeen: op.data.dSeen,
          coid: op.data.coid || null,
        }
        this.addPdfPage(addPdfPageData)
        break

      case 'addFolder':
        const addFolderData: Folder = {
          folderId: op.data.folderId,
          name: op.data.name,
          weight: op.data.weight,
          hidden:op.data.hidden,
          coid: op.data.coid || null,
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addFolder(addFolderData)
        break

      case 'addTag':
        const addTagData: Tag = {
          tagId: op.data.tagId,
          name: op.data.name,
          description: op.data.description,
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          coid: op.data.coid || null,
          tCreated: op.data.tCreated || null,
          tModified: op.data.tModified || null,
        }
        this.addTag(addTagData)
        break

      case 'addAnnotation':
        const addAnnotationData: iAnnotation = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'annotation',
          style: op.data.style,
          label: op.data.label,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addAnnotation(addAnnotationData)
        this.addInteractionToLookupTable(addAnnotationData)
        this.addPageToLookupTable(addAnnotationData)
        break

      case 'addComment':
        const addCommentData: iComment = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'comment',
          style: op.data.style,
          label: op.data.label,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tSeen: op.data.tSeen || 0,
          dSeen: op.data.dSeen || 0,
          tCreated: op.data.tCreated ,
          tModified: op.data.tModified ,
        }
        this.addComment(addCommentData)
        this.addInteractionToLookupTable(addCommentData)
        this.addPageToLookupTable(addCommentData)
        break

      case 'addLink':
        const addLinkData:iLink = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          linkId: op.data.linkId,
          linkType: op.data.linkType,
          which: op.data.which,
          interactionId: op.data.interactionId,
          interactionType: 'link',
          style: op.data.style,
          label: op.data.label,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tSeen: op.data.tSeen,
          dSeen: op.data.dSeen,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }

        this.addLink(addLinkData)
        this.addInteractionToLookupTable(addLinkData)
        this.addPageToLookupTable(addLinkData)
        break

      case 'addWeblink':
        const weblinkData: iWeblink = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'weblink',
          style: op.data.style,
          label: op.data.label,
          reactions: op.data.reactions,
          url: op.data.url,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tSeen: op.data.tSeen,
          dSeen: op.data.dSeen,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addWeblink(weblinkData)
        this.addInteractionToLookupTable(weblinkData)
        this.addPageToLookupTable(weblinkData)
        break

      case 'addTagging':
        const addTaggingData: iTag = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'tagging',
          style: op.data.style,
          label: op.data.label,
          tagId: op.data.tagId,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tSeen: op.data.tSeen,
          dSeen: op.data.dSeen,
          tCreated: op.data.tCreated ,
          tModified: op.data.tModified,
        }
        this.addTagging(addTaggingData)
        this.addInteractionToLookupTable(addTaggingData)
        this.addPageToLookupTable(addTaggingData)
        break

      case 'addEmotion':
        const addEmotionData: iEmotion = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          interactionType: 'emotion',
          style: op.data.style,
          emotionId: op.data.emotionId,
          label: op.data.label,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          coid: op.data.coid || null,
          tSeen: op.data.tSeen,
          dSeen: op.data.dSeen,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addEmotion(addEmotionData)
        this.addInteractionToLookupTable(addEmotionData)
        this.addPageToLookupTable(addEmotionData)
        break

      case 'addReadingQuestion':
        const addReadingQuestionData:iReadingQuestion = {
          userId: op.data.userId,
          userName: op.data.userName,
          usergroupId: op.data.usergroupId,
          interactionId: op.data.interactionId,
          label: op.data.label,
          reactions: op.data.reactions,
          anchor: {
            nodeId: op.data.anchor.nodeId,
            rects: op.data.anchor.rects.map((r:any) => ({
              x: Number(r.x),
              y: Number(r.y),
              w: Number(r.w),
              h: Number(r.h),
              p: Number(r.p)
            })),
            relText: op.data.anchor.relText,
            tool: op.data.anchor.tool
          },
          style: op.data.style,
          interactionType: 'readingQuestion',
          openUntil:op.data.openUntil,
          answerVisibility: op.data.answerVisibility,
          answerVisibilityDelay: op.data.answerVisibilityDelay,
          coid: op.data.coid || null,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
          tSeen: op.data.tSeen || 0,
          dSeen: op.data.dSeen || 0,
        }
        this.addReadingQuestion(addReadingQuestionData)
        this.addInteractionToLookupTable(addReadingQuestionData)
        this.addPageToLookupTable(addReadingQuestionData)
        break


      case 'editAnnotation': {
        const interaction = this.getInteraction(op.data.interactionId) as iAnnotation
        if (interaction) {
          if (typeof op.data.mods.tCreated !== 'undefined') interaction.tCreated = op.data.mods.tCreated
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.style !== 'undefined') interaction.style = JSON.parse(JSON.stringify(op.data.mods.style))
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }
        } break

      case 'editComment': {
        const interaction = this.getInteraction(op.data.interactionId) as iComment
        if (interaction) {
          if (typeof op.data.mods.tCreated !== 'undefined') interaction.tCreated = op.data.mods.tCreated
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }
        } break

      case 'editLink': {
        const interaction = this.getInteraction(op.data.interactionId) as iLink
        if (interaction) {
          const nodeId = interaction.anchor.nodeId
          if (typeof op.data.mods.tCreated !== 'undefined') interaction.tCreated = op.data.mods.tCreated
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            const newNodeId = op.data.mods.anchor.nodeId
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
            if (nodeId !== newNodeId) {
              this.content['pdfFiles'][op.data.mods.anchor.nodeId].links[op.data.interactionId] = {
                ...this.content['pdfFiles'][nodeId].links[op.data.interactionId],
              }
              delete this.content['pdfFiles'][nodeId].links[op.data.interactionId]
            }
          }
        }
        else {
          console.warn(`Did not find link ${op.data.interactionId}`)
        }
        } break

      case 'editTagging': {
        const interaction = this.getInteraction(op.data.interactionId) as iTag
        if (interaction) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${interaction.anchor.nodeId}/taggings/${op.data.interactionId}`)
          if (typeof op.data.mods.tagId !== 'undefined') interaction.tagId = op.data.mods.tagId
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }

      } break

      case 'editEmotion': {
        const interaction = this.getInteraction(op.data.interactionId) as iEmotion
        if (interaction) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${interaction.anchor.nodeId}/emotions/${op.data.interactionId}`)
          if (typeof op.data.mods.emotionId !== 'undefined') interaction.emotionId = op.data.mods.emotionId
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }
      } break

      case 'editWeblink': {
        const interaction = this.getInteraction(op.data.interactionId) as iWeblink
        if (interaction) {
          if (uiStore.showVerboseLogging.opProcessing) console.log(`Editing ${interaction.anchor.nodeId}/weblink/${op.data.interactionId}`)
          if (typeof op.data.mods.url !== 'undefined') interaction.url = op.data.mods.url
          if (typeof op.data.mods.label !== 'undefined') interaction.label = op.data.mods.label
          if (typeof op.data.mods.tModified !== 'undefined') interaction.tModified = op.data.mods.tModified
          if (typeof op.data.mods.anchor !== 'undefined' && anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }
      } break

      case 'editReadingQuestion': {
        const interaction = this.getInteraction(op.data.interactionId) as iReadingQuestion
        if (uiStore.showVerboseLogging.opProcessing) console.log(`Edit RQ ${JSON.stringify(interaction)} with ${JSON.stringify(op.data.mods)}`)
        if (interaction) {
          //if (typeof op.data.mods.coid                  !== 'undefined') interaction.coid                  = op.data.mods.coid
          if (typeof op.data.mods.tCreated              !== 'undefined') interaction.tCreated              = op.data.mods.tCreated
          if (typeof op.data.mods.tModified             !== 'undefined') interaction.tModified             = op.data.mods.tModified
          if (typeof op.data.mods.label                 !== 'undefined') interaction.label                 = op.data.mods.label
          if (typeof op.data.mods.openUntil             !== 'undefined') interaction.openUntil             = op.data.mods.openUntil
          if (typeof op.data.mods.answerVisibility      !== 'undefined') interaction.answerVisibility      = op.data.mods.answerVisibility
          if (typeof op.data.mods.answerVisibilityDelay !== 'undefined') interaction.answerVisibilityDelay = op.data.mods.answerVisibilityDelay
          if (typeof op.data.mods.anchor                !== 'undefined'&& anchorHasChanged(op.data.mods.anchor, interaction.anchor)) {
            this.deletePageFromLookupTable(interaction)
            interaction.anchor = JSON.parse(JSON.stringify(op.data.mods.anchor))
            this.addPageToLookupTable(interaction)
          }
        }
      } break


      case 'deleteAnnotation':
      case 'deleteComment':
      case 'deleteEmotion':
      case 'deleteReadingQuestion':
      case 'deleteTagging':
      case 'deleteWeblink':
        this.deleteInteraction(op.data.interactionId)
        break

      case 'deleteLink':{
        const iLoc = this.findInteraction(op.data.interactionId)
        if (iLoc) {
          const linking = this.content.pdfFiles[iLoc.nodeId].links[op.data.interactionId]
          this.deleteLink(linking.linkId)
        }
        } break

      case 'addThread': {
        if (uiStore.showVerboseLogging.opProcessing) console.log('add thread ', op.data )
        const addThreadData: Thread = {
          threadId: op.data.threadId,
          interactionId: op.data.interactionId,
          usergroupId: op.data.usergroupId,
          coid: op.data.coid || null,
          userId: op.data.userId,
          userName: op.data.userName,
          threadName: op.data.threadName || '',
          messages: [],
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addThread(addThreadData)
      } break

      case 'addMessage': {
        if (uiStore.showVerboseLogging.opProcessing) console.log('add message ', op.data )
        const addMessageData: Message = {
          messageId: op.data.messageId,
          threadId: op.data.threadId,
          refMessageId: op.data.refMessageId,
          coid: op.data.coid || null,
          userId: op.data.userId,
          userName: op.data.userName,
          text: op.data.text,
          reactions: op.data.reactions || {},
          tSeen: op.data.tSeen || 0,
          dSeen: op.data.dSeen || 0,
          tCreated: op.data.tCreated,
          tModified: op.data.tModified,
        }
        this.addMessage(addMessageData)
      } break

      case 'deleteMessage': {
        const thread = this.content.threads[op.data.threadId]
        if (thread) {
          const messageIndex = thread.messages.findIndex((msg:Message) => { return msg.messageId === op.data.messageId })
          if (messageIndex >= 0) {
            thread.messages.splice(messageIndex, 1)
          }
        }
      } break

      case 'editMessage': {
        const thread = this.content.threads[op.data.threadId]
        if (thread) {
          const messageIndex = thread.messages.findIndex((msg:Message) => { return msg.messageId === op.data.messageId })
          if (messageIndex >= 0) {
            if (typeof op.data.mods.text !== 'undefined') thread.messages[messageIndex].text = op.data.mods.text
            if (typeof op.data.mods.tModified !== 'undefined') thread.messages[messageIndex].tModified = op.data.mods.tModified; else thread.messages[messageIndex].tModified = op.tCreated || null
          }
        }
      } break

      case 'deletePod':
        Object.assign(this, emptyPod)
        this.status = 'deleted'
        break

      case 'editPod':
        if (typeof op.data.mods.allowDownload !== 'undefined')    this.allowDownload = op.data.mods.allowDownload
        if (typeof op.data.mods.name !== 'undefined')             this.name          = op.data.mods.name
        if (typeof op.data.mods.description !== 'undefined')      this.description   = op.data.mods.description
        if (typeof op.data.mods.tModified !== 'undefined')        this.tModified     = op.data.mods.tModified
        if (typeof op.data.mods.podImageFileId !== 'undefined')   this.podImageFileId= op.data.mods.podImageFileId
        if (typeof op.data.mods.podColor !== 'undefined')         this.podColor      = op.data.mods.podColor
        if (typeof op.data.mods.hasKeyphrase !== 'undefined')     this.hasKeyphrase  = op.data.mods.hasKeyphrase
        if (typeof op.data.mods.keyphrase !== 'undefined')        this.hasKeyphrase  = Boolean(op.data.mods.keyphrase !== null)
        if (typeof op.data.mods.oerSharedTo !== 'undefined')      this.oerSharedTo   = op.data.mods.oerSharedTo
        break

      case 'removeUserFromPod':
        if (this.userInfos[op.data.userId]) delete(this.userInfos[op.data.userId])
        const podGroupForRemoval = this.getUsergroupByRole('Pod')
        if (podGroupForRemoval) this.usergroups[podGroupForRemoval.usergroupId].members = podGroupForRemoval.members.filter(userId => userId !== op.data.userId)
        break

      case 'addUserToPod':
        if (!this.userInfos[op.data.userId]) {
          const newUser:UserInfo = {
            userId: op.data.userId,
            userName: op.data.userName,
            color: op.data.color,
            login: op.data.login || null,
            patronUserId: op.data.patronUserId,
          }
          this.userInfos[op.data.userId] = newUser
        }
        const podGroupForAddition = this.getUsergroupByRole('Pod')
        if ((podGroupForAddition) && (podGroupForAddition.members.indexOf(op.data.userId) === -1)) {
          this.usergroups[podGroupForAddition.usergroupId].members = [...this.usergroups[podGroupForAddition.usergroupId].members, op.data.userId].sort((a:number, b:number) => a - b)
        }
        break

      case 'editPermission':
        if (this.permissions['editPermission']) {
          const i = this.usergroups[op.data.usergroupId].permissions.indexOf(op.data.permission)
          if ((op.data.granted) && (i === -1)) {
            this.usergroups[op.data.usergroupId].permissions.push(op.data.permission)
          }
          if ((!op.data.granted) && (i > -1)) {
            this.usergroups[op.data.usergroupId].permissions.splice(i, 1)
          }

          const resultingPodwidePermission = Boolean(Object.keys(this.usergroups).filter((usergroupId:string) => { return this.usergroups[usergroupId].permissions.indexOf(op.data.permission) > -1 }).length)
          if (resultingPodwidePermission) this.permissions[op.data.permission] = true; else delete this.permissions[op.data.permission]

        }
        break

      case 'editUserInfo':
        if (this.userInfos[op.data.userId]) {
          if (typeof op.data.mods.userName !== 'undefined') this.userInfos[op.data.userId].userName = op.data.mods.userName
          if (typeof op.data.mods.color !== 'undefined') this.userInfos[op.data.userId].color = op.data.mods.color
          if (typeof op.data.mods.avatarFileId !== 'undefined') this.userInfos[op.data.userId].avatarFileId = op.data.mods.avatarFileId
        }
        break

      case 'addViews':
        op.data.views.forEach(view => {
          switch(view.type) {
            case 'message':
              const msg = this.getMessage(view.id)
              if (msg) {
                msg.tSeen = view.tSeen;
                msg.dSeen = op.data.canonical ? view.dSeen : view.dSeen + (msg.dSeen||0)
              }
              break

            case 'comment':
            case 'emotion':
            case 'link':
            case 'readingQuestion':
            case 'tagging':
            case 'weblink':
                const int = this.getInteraction(view.id)
                if (int) {
                  int.tSeen = view.tSeen;
                  int.dSeen = op.data.canonical ? view.dSeen : view.dSeen + (int.dSeen||0)
                }
                break

            case 'pdfPage':
              if (view.sub) {
                const page = this.content.pdfFiles[view.id]?.pages[view.sub]
                if (page) {
                  page.tSeen = view.tSeen;
                  page.dSeen = op.data.canonical ? view.dSeen : view.dSeen + (page.dSeen||0)
                }
              }
              break

            default:
              console.warn(`Cannot (yet) account for views on ${view.type}`)
          }
        })
        break

      case 'addReaction':
        const addReaction = (reactions:ReactionBlock, reaction:string, userId:number) => {
          if (!reactions[reaction]) reactions[reaction] = []
          if (reactions[reaction].indexOf(userId) === -1) reactions[reaction].push(userId)
        }
        switch(op.data.type) {
          case 'message':
            const msg = this.getMessage(op.data.id)
            if (msg) {
              addReaction(msg.reactions, op.data.reactionId, op.data.userId)
            }
            break

          case 'annotation':
          case 'comment':
          case 'emotion':
          case 'link':
          case 'readingQuestion':
          case 'tagging':
          case 'weblink':
              const i = this.getInteraction(op.data.id)
              if (i) {
                addReaction(i.reactions, op.data.reactionId, op.data.userId)
              }
              break

          default:
            console.warn(`Cannot (yet) process reactions on ${op.data.type}`)
        }
        break

      case 'deleteReaction':
        const deleteReaction =  (reactions:ReactionBlock, reaction:string, userId:number) => {
          if (reactions[reaction]) {
            const i = reactions[reaction].indexOf(userId)
            if (i > -1) reactions[reaction].splice(i, 1)
          }
        }
        switch(op.data.type) {
          case 'message':
            const msg = this.getMessage(op.data.id)
            if (msg) {
              deleteReaction(msg.reactions, op.data.reactionId, op.data.userId)
            }
            break

          case 'annotation':
          case 'comment':
          case 'emotion':
          case 'link':
          case 'readingQuestion':
          case 'tagging':
          case 'weblink':
              const i = this.getInteraction(op.data.id)
              if (i) {
                deleteReaction(i.reactions, op.data.reactionId, op.data.userId)
              }
              break

          default:
            console.warn(`Cannot (yet) process reactions on ${op.data.type}`)
        }
        break

      default:
        console.error(`Unknown op ${op.op} in PodClass.applyOp()`, op)
    }
  }

  addPdfFile(data: PdfFile) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addPdfFile got called as Class method`)
    this.content.pdfFiles[data.nodeId] = data
  }

  addPdfPage(data: PdfPage) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addPdfFile got called as Class method`)
    const file = data.nodeId ? this.content.pdfFiles[data.nodeId] : false
    if (file && data.no) {
      file.pages[data.no] = data
    }
  }

  addFolder(data: Folder) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addFolder got called as Class method`)
    this.content.folders[data.folderId] = data
  }

  addTag(data: Tag) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addTag got called as Class method`)
    this.content.tags[data.tagId] = data
  }

  addAnnotation(data: iAnnotation) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addAnnotation got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.annotations) file.annotations = {}
      if (typeof file.annotations[data.interactionId] === 'undefined') file.annotations[data.interactionId] = data; else mergeToAdd(file.annotations[data.interactionId], data)
    }
    else {
      console.warn(`Did not add annotation ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addComment(data: iComment) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addComment got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.comments) file.comments = {}
      if (typeof file.comments[data.interactionId] === 'undefined') file.comments[data.interactionId] = data; else mergeToAdd(file.comments[data.interactionId], data)
    }
    else {
      console.warn(`Did not add comment ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addLink(data: iLink) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addComment got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.links) file.links = {}
      if (typeof file.links[data.interactionId] === 'undefined') file.links[data.interactionId] = data; else mergeToAdd(file.links[data.interactionId], data)
      if (typeof this.content.links[data.linkId] === 'undefined') {
        this.content.links[data.linkId] = {
          linkId: data.linkId,
          linkType: data.linkType,
          src: '',
          dst: '',
        }
      }

      if (data.which === 'src') this.content.links[data.linkId].src = data.interactionId
      if (data.which === 'dst') this.content.links[data.linkId].dst = data.interactionId
    }
    else {
      console.warn(`Did not add linking ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  deleteLink(linkId:string) {
    const link  = this.content.links[linkId]
    if (link) {
      const srcInteraction = link.src ? this.findInteraction(link.src) : null
      const dstInteraction = link.dst ? this.findInteraction(link.dst) : null
      if (!srcInteraction) { console.error('Could not find the link srcInteraction', link.src); return }
      if (!dstInteraction) { console.error('Could not find the link dstInteraction', link.dst); return }
      if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${srcInteraction.nodeId}/links/${link.src}, ${dstInteraction.nodeId}/links/${link.dst}, and the link ${linkId} itself`)
      if (srcInteraction) this.deleteInteraction(link.src)
      if (dstInteraction) this.deleteInteraction(link.dst)
      delete this.content.links[linkId]
    }
  }

  addWeblink(data: iWeblink) {
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.weblinks) file.weblinks = {}
      if (typeof file.weblinks[data.interactionId] === 'undefined') file.weblinks[data.interactionId] = data; else mergeToAdd(file.weblinks[data.interactionId], data)
    }
    else {
      console.warn(`Did not add weblink ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addTagging(data: iTag) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addTagging got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.taggings) file.taggings = {}
      if (typeof file.taggings[data.interactionId] === 'undefined') file.taggings[data.interactionId] = data; else mergeToAdd(file.taggings[data.interactionId], data)
    }
    else {
      console.warn(`Did not add tagging ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addEmotion(data: iEmotion) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addEmotion got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.emotions) file.emotions = {}
      if (typeof file.emotions[data.interactionId] === 'undefined') file.emotions[data.interactionId] = data; else mergeToAdd(file.emotions[data.interactionId], data)
    }
    else {
      console.warn(`Did not add emotion ${data.interactionId}/${data.emotionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  addReadingQuestion(data: iReadingQuestion) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addReadingQuestion got called as Class method`, data)
    const file = this.content.pdfFiles[data.anchor.nodeId]

    if (file) {
      if (!file.readingQuestions) file.readingQuestions = {}
      if (typeof file.readingQuestions[data.interactionId] === 'undefined') file.readingQuestions[data.interactionId] = data; else mergeToAdd(file.readingQuestions[data.interactionId], data)
    }
    else {
      console.warn(`Did not add readingQuestion ${data.interactionId} because file ${data.anchor.nodeId} was missing: ${file}`)
    }
  }

  deleteInteraction(interactionId:string) {
    const interactionLocation = this.findInteraction(interactionId)
    if (interactionLocation) {
      if (uiStore.showVerboseLogging.opProcessing) console.log(`Deleting ${interactionLocation.nodeId}/annotations/${interactionId}`)

      // Delete threads tied to this interaction
      Object.keys(this.content.threads).forEach((threadId:string) => {
        if (this.content.threads[threadId].interactionId === interactionId) {
          delete this.content.threads[threadId]
        }
      })

      const interactionTypePlural = convertInteractionTypeSingularToPlural(interactionLocation.interactionType)

      if(interactionTypePlural) {
        // delete pages connected to this interaction from lookup entry
        const interaction = this.content.pdfFiles[interactionLocation.nodeId][interactionTypePlural][interactionId]
        this.deletePageFromLookupTable(interaction)
        // delete interaction and lookup entry
        delete this.content.pdfFiles[interactionLocation.nodeId][interactionTypePlural][interactionId]
        this.deleteInteractionFromLookupTable(interactionId)
      }

    }
  }

  addThread(data: Thread) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addThread got called as Class method`, data)
    if (typeof this.content.threads[data.threadId] === 'undefined') this.content.threads[data.threadId] = data
  }

  addMessage(data: Message) {
    if (uiStore.showVerboseLogging.opProcessing) console.log(`addMessage got called as Class method`, data)
    if (this.content.threads[data.threadId]) {
      const messageIndex = this.content.threads[data.threadId].messages.findIndex((msg:Message) => { return msg.messageId === data.messageId })
      if (messageIndex === -1) {
        //console.log(`Adding message to thread:`, data)
        this.content.threads[data.threadId].messages.push(data as Message)
      }
      else {
        //console.log(`Updating message ${messageIndex} in thread`)
        mergeToAdd(this.content.threads[data.threadId].messages[messageIndex], data)
      }

      const messageSort = (a: Message, b: Message) => {
        if (a.coid && b.coid) return a.coid - b.coid
        if (a.coid && !b.coid) return -1
        if (!a.coid && b.coid) return 1
        if (a.tCreated && b.tCreated) return a.tCreated - b.tCreated
        return 0
      }

      const sorted = [...this.content.threads[data.threadId].messages].sort(messageSort)
      //console.log(sorted.map((msg:Message) => msg.messageId).join(','))
      //console.log(this.content.threads[data.threadId].messages.map((msg:Message) => msg.messageId).join(','))
      if (sorted.map((msg:Message) => msg.messageId).join(',') !== this.content.threads[data.threadId].messages.map((msg:Message) => msg.messageId).join(',')) this.content.threads[data.threadId].messages.sort(messageSort)
    }
  }

  getPdfFiles() {
    return Object.keys(this.content.pdfFiles).map(nodeId => this.content.pdfFiles[nodeId])
  }

  getFolders() {
    return Object.keys(this.content.folders).map(folderId => this.content.folders[folderId])
  }

  getAnnotation(interactionId: string): iAnnotation | null {
    const nodeIds = Object.keys(this.content.pdfFiles)
    nodeIds.forEach(nodeId => {
      const s = this.getAnnotations(nodeId)
      if (s) return s[0] as iAnnotation;
    })
    return null
  }

  getMessage(messageId: string, threadId:string|null = null): Message | null {
    const threads = threadId ? {threadId: this.content.threads[threadId]} : this.content.threads
    var result = null
    Object.keys(threads).forEach((threadId) => {
      threads[threadId].messages.forEach((msg:Message) => {
        if (msg.messageId === messageId) result = msg
      })
    })
    return result
  }

  getNodeFromInteractionId(interactionId:string) {
    const interactionLocation = this.findInteraction(interactionId)
    if (interactionLocation) {
      switch(interactionLocation.nodeType) {
        case 'pdfFile':
          return this.content.pdfFiles[interactionLocation.nodeId]
        default:
          console.error('getNodeFromInteractionId could not find nodeType ', interactionLocation.nodeType)
          return null
      }
    }
    console.error('getNodeFromInteractionId could not find interactionId ', interactionId)
    return null
  }

  getThreadFromMessage(messageId: string): Thread | null {
    var result = null
    Object.keys(this.content.threads).forEach((threadId) => {
      this.content.threads[threadId].messages.forEach((msg:Message) => {
        if (msg.messageId === messageId) result = this.content.threads[threadId]
      })
    })
    return result
  }

  getAnnotations(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iAnnotation[] = []
    if (file && file.annotations) Object.keys(file.annotations).forEach(annotationId => {
      if (file.annotations && file.annotations[annotationId]) res.push(file.annotations[annotationId])
    })
    return res
  }

  getComments(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iComment[] = []
    if (file && file.comments) Object.keys(file.comments).forEach(commentId => {
      if (file.comments && file.comments[commentId]) res.push(file.comments[commentId])
    })
    return res
  }

  getLinks(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iLink[] = []
    if (file && file.links) Object.keys(file.links).forEach(linkId => {
      if (file.links && file.links[linkId]) res.push(file.links[linkId])
    })
    return res
  }

  getReadingQuestions(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iReadingQuestion[] = []
    if (file && file.readingQuestions) Object.keys(file.readingQuestions).forEach(readingQuestionId => {
      if (file.readingQuestions && file.readingQuestions[readingQuestionId]) res.push(file.readingQuestions[readingQuestionId])
    })
    return res
  }

  getLinkOther(link: iLink) {
    const otherName = link.which === 'src' ? 'dst' : 'src'
    const other = this.content.links[link.linkId] ? this.content.links[link.linkId][otherName] : undefined
    if (other) {
      const iLoc = other ? this.findInteraction(other) : undefined
      if (iLoc && other) return this.content.pdfFiles[iLoc.nodeId].links[other]
    }
    return false
  }

  getWeblinks(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null
    if (file && file.weblinks) {
      const res: iWeblink[] = Object.keys(file.weblinks).map(linkId => file.weblinks[linkId])
      return res
    }

    return null
  }

  getTaggings(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    // list of tags of a pdf
    const res: iTag[] = []
    if (file && file.taggings) Object.keys(file.taggings).forEach(tagId => {
      if (file.taggings && file.taggings[tagId]) res.push(file.taggings[tagId])
    })
    return res
  }

  getEmotions(nodeId: string) {
    const file = this.content.pdfFiles[nodeId]
    if (!file) return null

    const res: iEmotion[] = []
    if (file && file.emotions) Object.keys(file.emotions).forEach(emotionId => {
      if (file.emotions && file.emotions[emotionId]) res.push(file.emotions[emotionId])
    })
    return res
  }

  setStatus(status: PodLoadState) {
    this.status = status
  }

  setLoadStatus(status:number) {
    this.loadStatus = status
  }

  setLastSyncOid(oid:number) {
    if (oid) this.lastSyncOid = oid
  }

  checkForRole(role:'Admin'|'Pod'|'Private') {
    if (role === 'Private') return true
    const usergroupId = Object.keys(this.usergroups).find(usergroupId => this.usergroups[usergroupId].role === role)
    if (usergroupId) return true
    return false
  }

  getUsergroupByRole(role:'Admin'|'Pod'|'Private') {
    if (role==='Private') return {
      usergroupId: '*private',
      name: 'Private',
      role: 'Private',
      members: [],
      permissions: [],
    } as Usergroup
    const usergroupId = Object.keys(this.usergroups).find(usergroupId => this.usergroups[usergroupId].role === role)
    if (usergroupId) return this.usergroups[usergroupId] as Usergroup; else throw(new Error(`Could not resolve usergroup in Pod.getUsergroupByRole(${role}): ${JSON.stringify(this.usergroups)}`))
  }

  getInteractionFromThreadId(threadId: string) {
    const thread = this.content.threads[threadId]
    if (!thread) return null

    const interactionId = thread.interactionId
    if(interactionId) {
      const interaction = this.getInteraction(interactionId)
      if (interaction) return interaction
    }

    return null
  }

  fingerprint(hashed:boolean = true, userNeutral:boolean = false) {

    const hashAnchor = (anchor:any) => {
      return sessionStore.convertBase64.fromInt(murmurhash(`${anchor.nodeId} ${JSON.stringify(anchor.rects.map)} ${anchor.relText}`))
    }

    try {
      const folderNids   = Object.keys(this.content.folders).sort()
      const pdfFileNids  = Object.keys(this.content.pdfFiles).sort()
      const tagIds       = Object.keys(this.content.tags).sort()
      const linkIds      = Object.keys(this.content.links).sort()
      const usergroupIds = Object.keys(this.usergroups).sort()
      const threadIds    = Object.keys(this.content.threads).sort()

      var pdfFiles: string[] = []

      const folderData    = folderNids.map((folderId:string) => { const folder = this.content.folders[folderId]; return `folder:${folder.folderId}:${folder.name}:(${folder.coid}-${folder.tCreated}-${folder.tModified})` })
      const linkData      = linkIds.map((linkId: string) => { const link = this.content.links[linkId]; return `link:${linkId}:${link.linkType}->${link.src}/${link.dst}` })
      const tagData       = tagIds.map((tagId:string) => { const tag = this.content.tags[tagId]; return `tag:${tagId}: ${tag.name} / ${tag.description}: :(${tag.coid}-${tag.tCreated}-${tag.tModified})` })
      const usergroupData = usergroupIds.map((usergroupId:string) => { const usergroup = this.usergroups[usergroupId]; return `${usergroup.usergroupId}: ${usergroup.role}: ${usergroup.name}: [${usergroup.members.join(', ')}]` })
      const threadData    = threadIds.map((threadId:string) => {
        const thread = this.content.threads[threadId]
        const messages = thread.messages.map((msg:Message) => { return "\n  M:" + `${msg.messageId}: ${sessionStore.convertBase64.fromInt(murmurhash(msg.text ? msg.text: ""))}: (${msg.refMessageId}:${msg.userId}:${msg.coid}/${msg.tCreated}/${msg.tModified}` }).join("")
        return `Thread ${thread.threadId} (${thread.interactionId}/${thread.usergroupId}/${thread.threadName}):${messages}`
      })

      pdfFileNids.forEach((nodeId:string) => {
        const file = this.content.pdfFiles[nodeId]
        var info = `pdf:${file.nodeId}:${file.name}:(${file.coid}-${file.tCreated}-${file.tModified})`

        const annotationIds = Object.keys(file.annotations).sort()
        const annotations = annotationIds.map((interactionId: string) => { const interaction = file.annotations[interactionId]; return `${interaction.interactionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const commentIds = Object.keys(file.comments).sort()
        const comments = commentIds.map((interactionId: string) => { const interaction = file.comments[interactionId]; return `${interaction.interactionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const linkIds = Object.keys(file.links).sort()
        const links = linkIds.map((interactionId: string) => { const interaction = file.links[interactionId]; return `${interaction.interactionId}/${interaction.linkId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const weblinkIds = Object.keys(file.weblinks).sort()
        const weblinks = weblinkIds.map((interactionId: string) => { const interaction = file.weblinks[interactionId]; return `${interaction.interactionId}/${sessionStore.convertBase64.fromInt(murmurhash(interaction.url ? interaction.url: ""))}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label ? interaction.label : ""))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })
        const emotionsIds = Object.keys(file.emotions).sort()
        const emotions = emotionsIds.map((interactionId: string) => { const interaction = file.emotions[interactionId]; return `${interaction.interactionId}/${interaction.emotionId}:${sessionStore.convertBase64.fromInt(murmurhash(interaction.label))}|${hashAnchor(interaction.anchor)}|${interaction.coid}/${interaction.tCreated}/${interaction.tModified}` })

        if (annotations.length) info += (userNeutral ? "\n  omitted for neutrality" : "\n  " + `A:${annotations.join("\n  A:")}`)
        if (comments.length) info += "\n  " + `C:${comments.join("\n  C:")}`
        if (links.length) info += "\n  " + `L:${links.join("\n  L:")}`
        if (weblinks.length) info += "\n  " + `W:${weblinks.join("\n  L:")}`
        if (emotions.length) info += "\n  " + `L:${emotions.join("\n  L:")}`

        pdfFiles.push(info)
      })

      const fingerprint = `Pod: ${this.podId}`
                        + "\n" + (userNeutral ? 'omitted for neutrality' : usergroupData.join("\n"))
                        + "\n" + tagData.join("\n")
                        + "\n" + folderData.join("\n")
                        + "\n" + linkData.join("\n")
                        + "\n" + pdfFiles.join("\n")
                        + "\n" + (userNeutral ? 'omitted for neutrality' : threadData.join("\n"))

      if (hashed) return sessionStore.convertBase64.fromInt(murmurhash(fingerprint))
      return fingerprint
    }
    catch(e) {
      console.error(e)
      return ''
    }
  }

  /**
   * Finds an interaction in the current pod by looking in interactionsLookupTable
   * Returns the interactionId, th interactionType, the nodeId, and the nodeType of the interaction, or null
   */
  findInteraction(interactionId: string) {
    // serach for hash index
    const interactionLocation = this.interactionsLookupTable[interactionId]
    if(interactionLocation) {
      return interactionLocation
    }
    console.error('There is no hash index for this interactionId', interactionId)
    return null
  }

  getInteraction(interactionId: string) {
    const interactionLocation = this.findInteraction(interactionId)
    if(interactionLocation) {
      switch(interactionLocation.nodeType) {
        case 'pdfFile':
          const interactionTypePlural = convertInteractionTypeSingularToPlural(interactionLocation.interactionType)
          if(interactionTypePlural === null) return null
          // check if interaction exists
          if(this.content.pdfFiles[interactionLocation.nodeId] &&
             this.content.pdfFiles[interactionLocation.nodeId][interactionTypePlural] &&
             this.content.pdfFiles[interactionLocation.nodeId][interactionTypePlural][interactionLocation.interactionId]
          ) {
            return this.content.pdfFiles[interactionLocation.nodeId][interactionTypePlural][interactionLocation.interactionId]
          } else {
            if (uiStore.showVerboseLogging.loadPod) console.warn("orphan interaction", JSON.stringify(interactionLocation))
            return null
          }
        default:
          console.warn('pdfFile is the only nodeType yet', interactionLocation.nodeType)
          return null
      }
    }
    return null
  }

  getInteractionsFromPage(nodeId: string, pageNumber: number) {
    const hash = getPageLookupTableHash(nodeId, pageNumber)
    const pageInteractions = []
    for(const interactionId in this.pageLookupTable[hash]) {
      const interaction = this.getInteraction(interactionId)
      if(interaction) pageInteractions.push(interaction)
    }
    return pageInteractions
  }

  getInteractionsByCoordinates(nodeId: string, x: number, y: number, pageNumber: number, currentScale: number, pageElement: DOMRect) {
    const hash = getPageLookupTableHash(nodeId, pageNumber)
    const interactionList: {interaction: Interaction, menuAnchor: any}[] = []
    for(const interactionId in this.pageLookupTable[hash]) {
      const interaction = this.getInteraction(interactionId)
      if(interaction) {
        for(const rect of interaction.anchor.rects) {
          // test if click position is inside the rectangle
          if(x > rect.x  && (y > (rect.y)) && (x < (rect.x + rect.w)) && (y < rect.y + rect.h) && rect.p === pageNumber ) {
            interactionList.push({"interaction": interaction, menuAnchor: calculateMenuAnchor(interaction, currentScale, pageElement)})
          }
        }
      }
    }
    if(interactionList.length > 0) return interactionList
    return null
  }
}

//// helper functions

function anchorHasChanged(anchorBefore: interactionAnchor, anchorAfter: interactionAnchor) {
  if(anchorBefore.nodeId !== anchorAfter.nodeId) return true
  if(anchorBefore.relText !== anchorAfter.relText) return true
  if(anchorBefore.tool !== anchorAfter.tool) return true
  if(anchorBefore.rects.length !== anchorAfter.rects.length) return true
  for(const i in anchorBefore.rects) {
    const beforeRect = anchorBefore.rects[i]
    const afterRect = anchorAfter.rects[i]
    if(beforeRect.h !== afterRect.h) return true
    if(beforeRect.p !== afterRect.p) return true
    if(beforeRect.w !== afterRect.w) return true
    if(beforeRect.x !== afterRect.x) return true
    if(beforeRect.y !== afterRect.y) return true
  }
  return false
}

function getPageLookupTableHash(nodeId: string, pageNumber: number) {
  return `${nodeId}--${pageNumber}`
}

// calculate position for menu on selected interaction rects
function calculateMenuAnchor(interaction: Interaction, currentScale: number, pageElement: DOMRect) {
  // reverse engineer boundingClientRect, create anchor for menu position
  const getBoundingClientRect = () => {
    const rect = interaction.anchor.rects[interaction.anchor.rects.length-1]
    return {
      height: rect.h * currentScale,
      left: (rect.x * currentScale) + pageElement.x,
      top: (rect.y * currentScale) + pageElement.y,
      width: rect.w * currentScale,
      x: (rect.x * currentScale) + pageElement.x,
      y: (rect.y * currentScale) + pageElement.y
    }
  }
  const anchor = { getBoundingClientRect , nodeType: 1 }
  return anchor
}