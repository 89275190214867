import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Box, Button, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStore } from '../../../contexts/store'
import {
  PDFDocument,
} from 'pdf-lib'
import { saveAs } from 'file-saver'
import api, {env} from '../../../api/api'
import { humanReadableBytes } from "../../../helper/Helper"
import { richPdfData } from "../../../shared/src/types/ShrimpRichPdf"
import { Tag } from "../../../shared/src/types/Content"
import { downloadZip } from "client-zip"
import { Pod } from "../../../shared/src/types/Pod"
import { alert } from "../../../stores/alertStore"
import JSZip from "jszip"
import { Op_editPod } from "../../../shared/src/types/Ops"


export type FileInfo = {
  file:ArrayBuffer,
  uploadName:string,
  shrimpData: any,
}

type Zippable = {
  name: string,
  size: number,
  input: Uint8Array
}

//@ts-ignore
window.downloadZip = downloadZip

// { file, setFile } : { file:FileInfo|undefined, setFile:Function }
const PdfExporter = ({nodeId} : {nodeId:string|string[]}) => {

  const [ zipFile, setZipFile ]   = useState<null|Blob>(null)
  const [ step, setStep ]         = useState<null|string>(null)
  const [ rendered, setRendered ] = useState<Boolean>(false)

  const { podStore, sessionStore, alertStore, uploadStore, oerStore, opStore } = useStore()
  const { t } = useTranslation()

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
      oerStore.loadOerList()
    }
  })

  const pod = podStore.pod
  if (!pod) return null
  const podx = pod as Pod


  const enabled = (sessionStore.session.user.userId === podx.creator?.userId)
                  || (pod?.checkForRole('Admin'))

  const unshare = () => {
    const op:Op_editPod = {
      op: 'editPod',
      podId: pod.podId,
      data: {
        mods: {
          oerSharedTo: [],
        },
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }

  const share = (list:number[]) => {
    const op:Op_editPod = {
      op: 'editPod',
      podId: pod.podId,
      data: {
        mods: {
          oerSharedTo: list,
        },
        usergroupId: pod.getUsergroupByRole('Pod').usergroupId,
        userId: sessionStore.session.user.userId,
        userName: podStore.userPseudonym || '',
      }
    }
    opStore.doOp(op)
  }

  const doExport = async(nodeId:string) => {
    const node = podStore.pod?.content.pdfFiles[nodeId]
    const tags:{[tagId:string]: Tag} = podStore.pod?.content.tags || {}
    if (!node) return false
    setStep(t('Prepping') + ` ${node.name}`)

    const tagIdsInNode:{[tagId:string]:boolean} = {}
    Object.keys(node.taggings).forEach((interactionId:string) => { tagIdsInNode[node.taggings[interactionId].tagId] = true })

    // fetch the PDF
    setStep(t('Downloading') + ` ${node.name}`)
    const res:Response = await api.fetch(`${env.backendBaseUrl}/pdf/${nodeId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      pipeThrough:true,
      headers: {
        'X-SHRIMP-Id': sessionStore.session ? sessionStore.session.sessionId : "",
      }
    }) as Response

    const pdf = res.status === 200 ? await res.arrayBuffer() : null

    if (pdf) {
      setStep(t('Compiling') + ` ${node.name}`)

      const threadIds = Object.keys(pod.content.threads)
      const threadIdsForThisNode = threadIds.filter((threadId:string) => pod.getInteractionFromThreadId(threadId)?.anchor.nodeId === nodeId)

      const pdfDoc = await PDFDocument.load(pdf, {ignoreEncryption: true})

      // build the metadata
      const shrimpData:richPdfData = {
        version: 0.1,
        originNodeId: nodeId,
        downloadNodeId: nodeId,
        node: {
          name: node.name,
          description: node.description,
          hash: node.hash,
          weight: node.weight,
          annotations: node.annotations,
          comments: node.comments,
          emotions: node.emotions,
          links: node.links,
          readingQuestions: node.readingQuestions,
          taggings: node.taggings,
          weblinks: node.weblinks,
        },
        context: {
          folder: node.folderId ? pod.content.folders[node.folderId] : null,
          tags: Object.keys(tagIdsInNode).map((tagId:string) => tags[tagId]),
          threads: threadIdsForThisNode.map((threadId:string) => pod.content.threads[threadId]),
        }
      }

      // combine
      const buf = new TextEncoder().encode(JSON.stringify(shrimpData))

      await pdfDoc.attach(buf, `Shrimp`, {
        mimeType: 'application/json',
        description: 'SHRIMP Data',
        creationDate: new Date(),
        modificationDate: new Date(),
      })

      // export
      const pdfBytes = await pdfDoc.save()
      //setStep('')
      return {
        name: node.folderId ?  `${pod.content.folders[node.folderId].name}/${node.name.replace('/', '_')}.pdf` : `${node.name.replace('/', '_')}.pdf`,
        size: pdfBytes.byteLength,
        input: pdfBytes
      }
    }

    alertStore.push(alert(t(`Could not include file "{{filename}}."`, {filename: node.name}), 'error'))
    return false
  }

  const prepareDownload = async () => {
    var files:(false|{
      name: string,
      size: number,
      input: Uint8Array
    })[] = []
    try {
      //files = await Promise.all(nodeId.map((nid:string) => doExport(nid)))
      for(var i=0; i<nodeId.length; i++) {
        files.push(await doExport(nodeId[i]))
      }
    }
    catch (e) {
      console.warn(e)
      alertStore.push(alert(t(`Could not create all exports`) + ': ' + e, 'error'))
    }

    setStep(t('Zipping'))
    const successfulFiles:{
      name: string,
      size: number,
      input: Uint8Array
    }[] = files.filter(f => f !== false) as {
      name: string,
      size: number,
      input: Uint8Array
    }[]
    try {
      const zip = new JSZip()
      for(const f of successfulFiles) {
        zip.file(f.name, f.input)
      }

      const blob = await zip.generateAsync({
        type:'blob'
      })

      return blob
    }
    catch(e) {
      console.warn(e)
      alertStore.push(alert(t(`Could not create zip file`) + ': ' + e, 'error'))
    }
    setStep('')
  }

  // deprecated?
  if (typeof nodeId === 'string') {
    return <Box>
      <Button disabled={!enabled} onClick={async () => {
        const file = await doExport(nodeId)
        if (file) window.open(URL.createObjectURL(new Blob([file.input], {type: "application/pdf"})))
      }}>{t('Download')} {nodeId}</Button>
    </Box>
  }

  var info = null

  if (Boolean(step)) info = <Box>
    {<CircularProgress size={12} sx={{mr:1}} />}
  </Box>

  if (!Boolean(step) && zipFile) info = <Box>
    {t('Download Zip ({{size}})', {size: humanReadableBytes(zipFile?.size || 0)})}
  </Box>

  return <Box>
    {info}
    <Button style={{marginRight:10}} variant="outlined" disabled={!enabled || Boolean(zipFile) || Boolean(step)} onClick={async () => {
      const blob = await prepareDownload()
      if (blob) setZipFile(blob)
      setStep('')
      if (blob) saveAs(blob, `${pod.name}.zip`)
    }}>
      {t('Zip All for Export')}
    </Button>
    <Button variant="outlined" disabled={!enabled || Boolean(zipFile) || Boolean(step)} onClick={async() => {
      const blob = await prepareDownload()
      const file = {
        file: await new Response(blob).arrayBuffer(),
      }
      const oerFileId = pod.podId
      uploadStore.add(
        `${env.backendBaseUrl}/uploadOer`,
        pod.podId,
        {
          fileId: oerFileId,
          rel: pod.podId,
          type: 'oer',
          size: file.file.byteLength,
        },
        file.file,
        pod.podId + '.oer.zip',
        'application/zip',
        () => { share([1])  }, // success(id, file.shrimpData?.node?.name || file.originalFileName),
        () => {window.alert('failure')} // failure(file.shrimpData?.node?.name || file.originalFileName))
      )
      setStep('')
    }}>
      {pod.oerSharedTo.length ? t('Update OER') :  t('Publish as OER')}
    </Button>
    {
      (pod.oerSharedTo.length && Object.keys(oerStore.oerList).length) ? <Box>This pod is currently shared to {pod.oerSharedTo.length} platforms ({ pod.oerSharedTo.map((n:number) => oerStore.oerList[n].name).join(', ') }). <a href="#" onClick={unshare}>Unshare</a></Box> : null
    }
  </Box>


}

export default observer(PdfExporter)