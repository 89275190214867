import { makeObservable, observable, action } from 'mobx'
import api from '../api/api'

export interface oerStoreModel {
  oerList:{[oerId: number]: oerPlatform}
  loadOerList: () => void,
  status: 'idle'|'loading'|'error',
}

export type oerPlatform = {
  name:string,
}

class oerStore {

  oerList:{[oerId: number]: oerPlatform} = []
  status: 'idle'|'loading'|'error' = 'idle'

  constructor() {
    makeObservable(this, {
      oerList: observable,
      loadOerList: action,
    })
  }

  async loadOerList() {

    this.oerList[1] = {
      name:'Oersi'
    }

    this.oerList[2] = {
      name:'meinBildungsraum'
    }
    /*
    const res = await api.fetch(endpoint, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        podId,
        mimeType,
        ...signedUrlAttributes,
      })
    })
    */

  }

}

const exportOerStore = new oerStore()

export default exportOerStore