import uiStore, { uiStoreModel } from './uiStore'
import alertStore, { AlertStoreModel } from './alertStore'
import podStore, {PodStoreModel} from './podStore'
import sessionStore, { sessionStoreModel } from './sessionStore'
import broadcastStore, { broadcastStoreModel } from './broadcastStore'
import pdfMetaStore, { pdfMetaStoreModel } from './pdfMetaStore'
import opStore, { OpStoreModel } from './opStore'
import uploadStore, { uploadStoreModel } from './uploadStore'
import pdfImportStore, { pdfImportStoreModel } from './pdfImportStore'
import oerStore, { oerStoreModel } from './oerStore'

export type RootStoreModel = {
    uiStore: uiStoreModel,
    alertStore: AlertStoreModel,
    podStore: PodStoreModel,
    sessionStore: sessionStoreModel,
    broadcastStore: broadcastStoreModel,
    pdfMetaStore: pdfMetaStoreModel,
    oerStore: oerStoreModel,
    opStore: OpStoreModel,
    uploadStore: uploadStoreModel,
    pdfImportStore: pdfImportStoreModel,
  }

const rootStore: RootStoreModel = {
    uiStore,
    alertStore,
    podStore,
    sessionStore,
    broadcastStore,
    pdfMetaStore,
    oerStore,
    opStore,
    uploadStore,
    pdfImportStore,
}

export default rootStore;